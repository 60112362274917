<template>
  <div class="agreement">
    <div class="agreement-text">
      <p>Политика обработки персональных данных в ООО «УЗВ»</p>
      <p>
        Настоящая Политика обработки персональных данных (далее – «Политика»)
        описывает, как ООО «УЗВ» (далее – «Компания») обрабатывает персональные
        данные, какие права предоставлены вам в связи с такой обработкой.
      </p>
      <p>1. Пределы действия Политики</p>
      <p>1.1. Настоящая Политика применяется к:</p>
      <p>
        · прикладному программному обеспечению «WhenSpeak» (далее - ПО
        «WhenSpeak», ПО, программное обеспечение), доступ к которому
        предоставляется по следующему адресу (URL) в сети Интернет:
        https://app.whenspeak.ru/ и https://ws.live/ Это также относится ко
        всему программному обеспечению, которое будет выпущено после вступления
        в силу настоящей Политики, лицензионным и иным договорам, которыми
        предоставляются права на использование программного обеспечения;
      </p>
      <p>
        · сайтам клиентских приложений ПО «WhenSpeak» для мобильных платформ
        («мобильных приложений»), загрузка которых непосредственно на ваше
        мобильное устройство возможна посредством официальных «магазинов
        приложений» производителей соответствующих мобильных платформ «Google®
        Play®» (play.google.com) и «Apple® iTunes® AppStore» (itunes.apple.com);
      </p>
      <p>
        · сайтам по адресам (URL) в сети Интернет: https://whenspeak.ru/ и/или
        https://ws.live/ и их поддоменам (далее – «Cайты»);
      </p>
      <p>
        · сопутствующим услугам и сервисам. Например, к технической поддержке, к
        проводимым обучающим онлайн-мероприятиям, конференциям, конкурсам (далее
        – «Сопутствующие услуги»);
      </p>
      <p>
        · запросам в техническую поддержку, другие отделы и в онлайн-чатах
        (далее – «Запросы»);
      </p>
      <p>· обработке данных, связанных с трудоустройством.</p>
      <p>
        1.2. Если вы не согласны с настоящей Политикой, не пользуетесь Сайтами и
        программным обеспечением Компании.
      </p>
      <p>
        1.3. Настоящая Политика обработки персональных данных не
        распространяется на:
      </p>
      <p>
        · программное обеспечение, не указанное выше, в том числе на интеграции
        с ПО «WhenSpeak»;
      </p>
      <p>
        · сервисы сторонних разработчиков или поставщиков, платформы, социальные
        сети, услуги, которые могут быть доступны через ПО «WhenSpeak» и Сайты
        Компании или интегрированы с ними, равно ссылки на них (далее –
        «Сторонние сервисы»). Например, при оплате вы можете быть перенаправлены
        на сайт платёжной системы. Обработка данных в этих случаях находится вне
        контроля Компании. Такие Сторонние сервисы являются самостоятельными
        операторами данных (контролёрами) и действуют на основании собственных
        политик обработки персональных данных.
      </p>
      <p>
        1.4. Особые правила, уточняющие положения настоящей Политики, могут
        содержаться в договорах, правилах, политиках, регламентах, определяющих
        условия предоставления ПО «WhenSpeak». Вам необходимо внимательно
        ознакомиться и согласиться с этими документами.
      </p>
      <p>1.5. Настоящая Политика составлена на русском языке.</p>
      <p>2. Правовые основания обработки персональных данных</p>
      <p>
        2.1. Обработка персональных данных осуществляется в соответствии c
        требованиями законодательства Российской Федерации (далее – «Применимое
        законодательство»):
      </p>
      <p>· Гражданским кодексом Российской Федерации;</p>
      <p>· Трудовым кодексом Российской Федерации;</p>
      <p>
        · Федеральным законом Российской Федерации от 27 июля 2006 г. №152-ФЗ «О
        персональных данных»;
      </p>
      <p>
        · Федеральным законом Российской Федерации от 27 июля 2006 г. №149-ФЗ
        «Об информации, информационных технологиях и о защите информации».
      </p>
      <p>
        2.2. Компания обрабатывает персональные данные с учётом требований
        General Data Protection Regulation (GDPR).
      </p>
      <p>3. Собираемая информация</p>
      <p>
        3.1. Компания может обрабатывать персональные данные и иную информацию
        (в том числе анонимизированную), прямо не относящуюся к персональным
        данным. Например, технические характеристики устройств, подключений,
        отчёты об ошибках, способы взаимодействия с интерфейсами (далее –
        «Дополнительные данные»).
      </p>
      <p>3.2. Компания получает информацию из следующих источников:</p>
      <p>
        3.2.1. Персональные данные, предоставляемые пользователями. Такую
        информацию пользователь предоставляет во время использования
        функциональности Сайтов, для создания учетной записи пользователей
        Сайтов с возможностью заказа ПО «WhenSpeak» и/или оплаты приобретаемых
        лицензий на программное обеспечение Компании, для заключения соглашений
        /договоров с Компанией, при подписке на новостную или рекламную
        рассылку, а также когда направляет Запросы и использует Сопутствующие
        услуги. Эти данные необходимы Компании для исполнения своих договорных
        обязательств, проведения платежей, исполнения налоговых обязательств,
        защиты от претензий и исков. К ним относятся фамилия, имя, адрес
        электронной почты, номер телефона. В зависимости от содержания
        обязательств, может требоваться предоставление иных персональных данных.
        Компания не производит сбор и хранение данных платёжных карт.
      </p>
      <p>
        3.2.2. Дополнительные данные, обрабатываемые при использовании ПО
        «WhenSpeak», Сайтов и Сопутствующих услуг:
      </p>
      <p>
        · Log-данные (данных журналов). Наши серверы в автоматическом режиме
        производят записи технической информации о доступе к ним. Это
        происходит, когда вы открываете Сайт, используете ПО «WhenSpeak» или
        пользуетесь Сопутствующими услугами. Log-данные могут содержать
        IP-адрес, характеристики браузера, данные о дате, времени, длительности
        и последовательности действий.
      </p>
      <p>
        · Данные об использовании. Компания собирает информацию о том, как вы
        использовали ПО «WhenSpeak» и Сайты, чтобы сделать их удобнее. Такие
        данные могут включать в себя тип устройства, версию операционной
        системы, технические отчёты об ошибках, количественные параметры
        использования. Набор данных определяется тем, каким Сайтом или
        программным обеспечением вы воспользовались.
      </p>
      <p>
        · Данные, предоставляемые Компании третьими лицами. В большинстве
        случаев вы вправе самостоятельно разрешить, запретить или ограничить
        раскрытие информации третьей стороной. Например, вы можете
        авторизоваться на Сайтах или в ПО «WhenSpeak» с помощью социальных
        сетей. При этом вы самостоятельно разрешаете социальной сети
        предоставить Компании доступ к общедоступной информации о вас или
        информации, которую вы открыли для всех (например, имя, идентификатор
        пользователя, изображение профиля) и к информации, к которой вы
        разрешили доступ для Сайтов Компании или программного обеспечения
        Компании. Используя аутентификацию через социальные сети, вы разрешаете
        Компании получать и обрабатывать сохраненную социальной сетью информацию
        о вас, которая является для Компании доступной как указано выше.
        Компания не может получить какую-либо информацию самостоятельно, если вы
        не разрешили этого в настройках социальной сети. Компания рекомендует
        вам ознакомиться с политикой конфиденциальности и любыми положениями и
        условиями для метода аутентификации, который вы собирается использовать.
      </p>
      <p>
        · Файлы cookie. С целью обеспечения эффективного управления сайтами,
        содействия в настройке интерфейса пользователя ПО, Компания может
        использовать файлы cookie (небольшие по размеру текстовые файлы,
        хранящиеся в браузере посетителя, сохраняющие параметры настройки
        интерфейса пользователя), технологии web-beacons (веб-маяки —
        электронные изображения, которые используются для учета количества
        посещений определенной страницы, не собирая при этом никакой
        идентификационной информации) совместно с tracking pixels (пиксели
        слежения), технологии сервисов «Яндекс.Метрика» (Яндекс® аналитика) и
        «Google Analytics» (Google® аналитика), позволяющие сайтам и
        программному обеспечению производить подсчет количества посетителей,
        зашедших на определенную страницу сайтов и обеспечивать доступ к
        определенным функциям сайтов и программного обеспечения.
      </p>
      <p>
        3.2.2.1. Собранная типовая техническая информация используется
        исключительно в статистических целях для исполнения договоров с
        пользователем. Компания не использует персональные данные в целях личной
        идентификации пользователей. Однако при авторизации зарегистрированных
        пользователей на сайтах в качестве пользователя программного обеспечения
        может использовать данную информацию в сочетании со сведениями,
        получаемыми из средств анализа данных и с использованием cookie-файлов
        для того, чтобы проанализировать, как посетители используют сайты и
        программное обеспечение.
      </p>
      <p>
        3.2.2.2. Используя сайты и программное обеспечение, вы дает свое
        согласие на то, чтобы Компания могла загружать cookie-файлы на ваше
        устройство (формировать их с использованием сайтов и программного
        обеспечения) согласно описанным выше условиям.
      </p>
      <p>
        3.2.2.3. Вы имете возможность управлять файлами cookie, обратившись в
        настройки браузера. В случае удаления файлов cookie будут удалены все
        данные о ваших предпочтениях (в частности о настройках браузера при
        работе с данными сайтами), включая предпочтение об отказе от
        использования файлов cookie. В случае блокирования файлов cookie
        изменения могут отразиться на интерфейсе пользователя, и некоторые
        компоненты Сайтов и программного обеспечения могут стать недоступными.
      </p>
      <p>
        3.3. Компания не производит обработку специальных категорий данных:
        биометрические данные, сведения о политических, религиозных убеждениях,
        судимостях, состоянии здоровья и иные аналогичные данные.
      </p>
      <p>
        3.4. Компания настоятельно рекомендует не указывать персональные данные
        в публичных разделах Сайтов, в чатах и других публичных модулях ПО
        «WhenSpeak». Компания не осуществляет обязательной модерации вводимых
        данных и не несёт ответственности в случае их публичного раскрытия.
      </p>
      <p>
        3.5. Если вы откажетесь предоставить Компании определенные персональные
        данные, вы все равно сможете получить доступ к Сайтам Компании, однако
        вы не сможете направить заявку, оплатить продукты, связаться с Компанией
        определенным образом, зарегистрироваться/ авторизоваться в качестве
        пользователя Сайтов, активировать или использовать определенную
        функциональность программного обеспечения или получать определенную
        информацию о продуктах и услугах Компании.
      </p>
      <p>4. Как Компания использует персональные данные</p>
      <p>
        4.1. Компания использует персональные данные строго в соответствии с
        применимым законодательством и целями, для которых данные собираются.
      </p>
      <p>
        4.2. При обработке персональных данных Компания исходит из следующих
        принципов:
      </p>
      <p>
        · Обработка персональных данных осуществляется на законной и
        справедливой основе;
      </p>
      <p>
        · Обработка персональных данных ограничивается достижением конкретных,
        заранее определённых и законных целей, а содержание и объем
        обрабатываемых данных не должен быть избыточным. Не допускается
        обработка персональных данных, несовместимая с целями сбора;
      </p>
      <p>
        · Не допускается объединение баз данных, содержащих персональные данные,
        обработка которых осуществляется в целях, несовместимых между собой;
      </p>
      <p>
        · Обработке подлежат только персональные данные, которые отвечают целям
        их обработки;
      </p>
      <p>
        · При обработке персональных данных обеспечивается точность персональных
        данных, их достаточность и актуальность по отношению к целям обработки
        персональных данных, а в необходимых случаях принимаются необходимые
        меры по удалению или уточнению неполных или неточных данных;
      </p>
      <p>
        · Хранение персональных данных не дольше, чем этого требуют цели
        обработки персональных данных;
      </p>
      <p>
        · Обеспечение безопасности от несанкционированной или незаконной
        обработки, а также от случайной потери, повреждения или уничтожения.
      </p>
      <p>4.3. Персональные данные используются Компанией для:</p>
      <p>
        · Исполнения взаимных обязательств Компании и пользователя на основании
        соглашений / договоров;
      </p>
      <p>
        · Соблюдения требований законодательства. Например, в целях соблюдения
        законодательства об уплате налогов;
      </p>
      <p>
        · Проведения платежей, управления счетами и их администрирования,
        контроля оплаты;
      </p>
      <p>
        · Защиты от нарушения авторских прав, мошенничества и иного
        недобросовестного поведения;
      </p>
      <p>
        · Ведение кадрового учета работников Компании, начисление им заработной
        платы, осуществление медицинского страхования;
      </p>
      <p>
        · Предоставление возможности безопасного использования функциональности
        Сайтов, в том числе: (а) возможности регистрации в качестве пользователя
        в сайтах, (б) последующей авторизации и идентификации в интерфейсе
        пользователя Сайтов для предоставления доступа к отдельным вебстраницам
        Сайтов, функционирующим сервисам и информации, предоставляемым
        пользователю посредством Сайтов;
      </p>
      <p>
        · Предоставление возможности безопасного использования производимых
        Компанией и взаимодействующих с сайтами клиентских приложений ПО
        «WhenSpeak» для мобильных платформ («мобильных приложений»), загрузка
        которых непосредственно на мобильное устройство конечного пользователя
        возможна посредством официальных «магазинов приложений» производителей
        соответствующих мобильных платформ «Google® Play®» (play.google.com) и
        «Apple® iTunes® AppStore» (itunes.apple.com) или по прямой веб-ссылке;
      </p>
      <p>
        · Улучшения, поддержки, модернизации ПО «WhenSpeak»и Сайтов. Такая
        обработка необходима для предотвращения и устранения ошибок, обеспечения
        безопасности, исправления технических проблем, анализа и мониторинга
        использования;
      </p>
      <p>
        · Взаимодействия с пользователем, например, ответов на запросы и прямых
        контактов по вопросам установки, настройки и приобретения ПО
        «WhenSpeak». Для этого используются данные, предоставленные
        пользователем при заказе ПО «WhenSpeak»;
      </p>
      <p>
        · Рассылки почты и других видов коммуникаций. При этом Компания может
        отправлять несколько видов сообщений (включая email, SMS, сообщения и
        рассылки посредством ПО «WhenSpeak»). Системные сообщения требуются для
        своевременного исполнения обязательств Компании перед пользователем. Они
        необходимы для обеспечения функционирования ПО «WhenSpeak» и Сайтов. К
        таким уведомлениям относятся сообщения технической поддержки, о
        платежах, приобретенных лицензиях и других подобных вопросах.
        Маркетинговые и рекламные включая уведомления (рассылки) о новостях и
        продуктах Компании, сообщения направляются только в случае, если
        пользователь предоставил явное согласие на их получение. В любой момент
        и время, на безвозмездной основе вы, как зарегистрированный пользователь
        Сайтов и владелец неисключительных лицензий на ПО «WhenSpeak», вправе
        отказаться от получения специальных уведомлений (рассылок) и иной
        информации о продуктах и услугах Компании, используя специальную
        процедуру отказа от таких рассылок, предусмотренную Компанией и
        указанную непосредственно в тексте (теле) писем соответствующих
        рассылок.
      </p>
      <p>· Проведения исследований с использованием обезличенных данных.</p>
      <p>
        4.4. При обработке данных в целях, указанных в п. 4.3 Политики — за
        исключением маркетинговых и рекламных сообщений — Компания исходит из
        наличия обоснованного законного интереса.
      </p>
      <p>5. Хранение данных</p>
      <p>
        5.1. Компания хранит данные в течение времени, необходимого для целей их
        сбора. К примеру, пользователь приобрёл лицензию на программное
        обеспечение сроком на 1 год. Применимое законодательство предусматривает
        срок на предъявление претензий и исков - 3 года. Таким образом, Компания
        будет хранить данные в течение срока исполнения лицензионного договора
        (1 год) и в течение срока на предъявление претензий (3 года), то есть
        всего 4 года.
      </p>
      <p>
        5.2. Несмотря на запрос пользователя об удалении информации, Компания
        вправе хранить информацию, если это необходимо для законных деловых
        интересов, исполнения обязательств и разрешения споров.
      </p>
      <p>
        5.3. По достижении целей обработки или в случае утраты необходимости в
        достижении этих целей, данные подлежат уничтожению либо обезличиванию.
      </p>
      <p>6. Раскрытие информации</p>
      <p>
        6.1. Компания раскрывает информацию третьим лицам только в случаях,
        предусмотренных настоящей Политикой или применимым законодательством, и
        только в объёме, необходимом исходя из цели раскрытия.
      </p>
      <p>6.2. Так, раскрытие допускается:</p>
      <p>
        · При получении указания от пользователя. В частности, под указанием
        пользователя будет пониматься, в том числе регистрация на конкретное
        онлайн-мероприятие или оплата такого мероприятия, так как в ПО
        «WhenSpeak» встроена функция сбора персональных данных в привязке к
        конкретному онлайн-мероприятию. Раскрытие в данном случае будет
        осуществляться для обеспечения возможности использовать ПО «WhenSpeak»
        для участия в организованном третьими лицами (организаторами) по
        договорам с Компанией онлайн-мероприятиях, и, одновременно, для
        обеспечения возможности третьего лица принять решение о самой
        возможности вашего участия в организованном таким третьим лицом
        онлайн-мероприятии. Для исполнения вашего поручения и осуществление
        вашего допуска к онлайн-мероприятию, ведённые вами данные будут
        направлены организатору того онлайн-мероприятия, в котором вы желаете
        принять участие, и для подключения к которому вы будете использовать ПО
        «WhenSpeak». Организатор данного онлайн-мероприятия, действуя по
        собственному усмотрению, без уведомления Компании, может направлять в
        ваш адрес информационные сообщения, в том числе, действуя в рамках
        договора с Компанией, использовать функции ПО «WhenSpeak» для рассылки
        напоминаний о конкретном мероприятии, ваше участие в котором
        санкционировано таким организатором, и/или других организуемых
        организатором с использованием ПО «WhenSpeak» вебинарах.
      </p>
      <p>
        · Провайдерам дополнительных услуг по поручению Компании. Например,
        сервисам, производящим email рассылки, мониторинг активности на Сайтах,
        субисполнителям, лицензиарам. Такие провайдеры будут использовать
        персональные данные только в соответствии с инструкциями Компании и в
        целях, указанных в этой Политике. Дополнительная информация о пределах
        раскрытия и лицах, которым данные могут быть раскрыты, содержится в
        разделе Процессоры.
      </p>
      <p>
        · В целях соблюдения применимого законодательства. Так, данные могут
        быть раскрыты в случае поступления запроса компетентного органа.
      </p>
      <p>
        · Аффилированным лицам Компании в целях исполнения обязательства перед
        пользователем. Например, для осуществления технической поддержки.
        Аффилированные лица подлежат раскрытию в разделе Процессоры.
      </p>
      <p>
        · В целях предотвращения незаконных действий, защиты прав и обеспечения
        безопасности Компании и иных лиц, защиты от претензий и исков.
      </p>
      <p>
        · При передаче информации во время реорганизации Компании, а равно
        заключении соглашений о передаче прав по договорам. В этом случае
        Компания вправе раскрыть информацию лицу, приобретающему права по
        договорам, а также лицу, которое образуется в результате реорганизации.
      </p>
      <p>
        · Обобщённых, агрегированных и неперсонализированных данных. Компания
        вправе раскрывать неперсонализированные статистические данные, например,
        о наиболее часто используемых функциях программного обеспечения.
      </p>
      <p>· В иных случаях при получении согласия пользователя.</p>
      <p>7. Безопасность</p>
      <p>
        7.1. В целях защиты данных Компанией принимаются серьёзные технические и
        организационные меры. В частности:
      </p>
      <p>
        · назначено лицо, ответственное за организацию обработки персональных
        данных;
      </p>
      <p>
        · утверждены локальные акты по вопросам обработки персональных данных.
        Они устанавливают процедуры для предотвращения и выявление нарушений,
        устранение последствий таких нарушений;
      </p>
      <p>
        · применяются правовые, организационные и технические меры по
        обеспечению безопасности персональных данных при их обработке;
      </p>
      <p>
        · проводятся периодические проверки условий обработки персональных
        данных;
      </p>
      <p>
        · производится ознакомление работников, непосредственно осуществляющих
        обработку персональных данных, с положениями законодательства о
        персональных данных, в том числе требованиями к защите персональных
        данных, документами, иными внутренними документами по вопросам обработки
        персональных данных.
      </p>
      <p>
        7.2. Компания не может гарантировать, что информация во время её
        передачи через интернет или при хранении будет абсолютно ограничена от
        вторжений. При этом Компания применяет псевдонимизацию и шифрование
        данных там, где это возможно.
      </p>
      <p>8. Конфиденциальность персональных данных</p>
      <p>
        8.1. Информация, относящаяся к персональным данным, ставшая известной
        Компании, является конфиденциальной информацией и охраняется законом.
      </p>
      <p>
        8.2. Работники Компании и иные лица, получившие доступ к обрабатываемым
        персональным данным, подписали обязательство о неразглашении
        конфиденциальной информации, а также предупреждены о возможной
        дисциплинарной, административной, гражданско-правовой и уголовной
        ответственности в случае нарушения норм и требований действующего
        законодательства РФ в области обработки персональных данных.
      </p>
      <p>
        8.3. Договоры Компании с Процессорами содержат условия
        конфиденциальности передаваемых и получаемых персональных данных.
      </p>
      <p>9. Возрастные ограничения</p>
      <p>
        9.1. Компания не производит обработку персональных данных лиц, которые
        не достигли возраста 14 лет.
      </p>
      <p>
        9.2. Если вы считаете, что Компанией могли быть получены персональные
        данные от лица, не достигшего возраста, с которого он может
        предоставлять самостоятельное согласие на обработку, сообщите нам об
        этом, воспользовавшись одним из способов, описанных в разделе «Оператор
        (Контролер) данных. Контактная информация».
      </p>
      <p>10. Изменение Политики</p>
      <p>
        10.1. Компания вправе вносить изменения в настоящую Политику с учётом
        изменений законодательства, бизнес-процессов. Новая редакция Политики
        вступает в силу с момента ее подписания уполномоченным лицом
        (Генеральный директор Компании) и официального опубликования электронной
        версии на сайте Компании по следующему адресу постоянного размещения в
        сети Интернет: https://app.whenspeak.ru/personal-data-agreement
      </p>
      <p>11. Место обработки данных, международная передача</p>
      <p>
        11.1. Компания обеспечивает сбор персональных данных и их обработку с
        использованием баз данных, находящихся на территории Российской
        Федерации.
      </p>
      <p>
        11.2. Компания может передавать данные без получения указания от
        пользователей Процессорам, указанным в соответствующем приложении,
        находящимся вне места нахождения Компании. При этом Процессоры будут
        обеспечивать уровень защиты данных не менее установленного настоящей
        Политикой.
      </p>
      <p>12. Оператор (Контролер) данных. Контактная информация</p>
      <p>
        12.1. Оператором (контролером) данных является: ООО «УЗВ», организация,
        зарегистрированная на территории РФ в соответствии с действующим
        законодательством: ИНН 7714362217, адрес местонахождения: 123290, РФ, г.
        Москва, 1-й Магистральный тупик, д.5А, ком. 132л.
      </p>
      <p>
        12.2. В целях реализации своих прав и законных интересов, вы вправе
        обратиться к Компании за предоставлением информации, касающейся
        обработки и защиты данных, по следующему адресу электронной почты
        info@whenspeak.ru или направив письмо по адресу: ООО «УЗВ», 123290, РФ,
        г. Москва, 1-й Магистральный тупик, д.5А, ком. 132л.
      </p>
      <p>
        12.3. Компания будет стремиться рассматривать поступающие обращения в
        максимально короткий срок, но в любом случае не более одного месяца.
      </p>
      <p>
        12.4. Компания рассматривает обращения, дает разъяснения и предпринимает
        меры по защите данных. В случае претензий и жалоб с вашей стороны,
        Компания принимает все необходимые меры для устранения возможных
        нарушений, установления виновных лиц и урегулирования спорных ситуаций в
        досудебном порядке.
      </p>
      <p>
        12.5. В случаях использования ПО «WhenSpeak»третьими лицами для оказания
        с их помощью услуг (например, организация онлайн-мероприятий) или
        заключение договоров сублицензирования, Компания будет признаваться
        контроллером только данных, собираемых ПО «WhenSpeak»в целях защиты
        авторских прав.
      </p>
      <p>13. Права субъектов данных</p>
      <p>
        13.1. Пользователю гарантируется соблюдение его прав в соответствии с
        Применимым законодательством и признанными нормами международного права.
        Пользователь имеет право на: · Отзыв согласия на обработку персональных
        данных (если обработка основана на согласии). Например, соглашаясь на
        получение рекламной или маркетинговой информации, вы всегда можете
        отписаться в любом письме такой рассылки;
      </p>
      <p>
        · Получение информации об обработке персональных данных. Поддерживая
        настоящую Политику в актуальной редакции, Компания обеспечивает вам
        получение достоверной информации о собираемых данных, целях обработки и
        иных аспектах обработки;
      </p>
      <p>
        · Доступ к информации, её обновление, изменение, уточнение информации по
        обращению в Компанию.
      </p>
      <p>
        · Право на возражение против обработки. Пользователь вправе возражать
        против обработки его персональных данных для целей прямого маркетинга;
      </p>
      <p>
        · Право на ограничение обработки. Такое право допускает только в строго
        определённых случаях, таких как, например, оспаривание точности данных
        (в период уточнения данных), установления неправомерности обработки. В
        случае заявления об ограничении обработки Компания не вправе без
        согласия пользователя производить любые виды обработки. Однако без
        такого согласия может производиться обработка, необходимая для защиты от
        претензий и исков;
      </p>
      <p>
        · Право на экспорт данных, а если это технически применимо – на импорт.
        Такое право может быть реализовано пользователем по его запросу в
        Компанию;
      </p>
      <p>
        · Право на удаление. Компания вправе продолжить обработку данных после
        поступления запроса, если это допускается применимым законодательством;
      </p>
      <p>
        · Иные дополнительные права могут быть предоставлены применимым
        законодательством.
      </p>
      <p>14. Процессоры Компании</p>
      <p>
        14.1. Компания может привлекать исполнителей, провайдеров сервисов и
        услуг (Далее – Процессоров), чтобы исполнить обязательства перед
        пользователями, а также в целях обеспечения своих обоснованных законных
        интересов.
      </p>
      <p>
        14.2. Процессорам передаётся строго ограниченный набор данных,
        необходимый для исполнения их функций. Перед привлечением процессора
        Компания примет все зависящие меры, чтобы обеспечить конфиденциальность
        передачи данных. В настоящем разделе Компания раскрывает таких лиц, их
        местонахождение и выполняемую функцию.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PDAgreement",
};
</script>

<style scoped lang="less">
.agreement {
  background-color: white;
  height: 100%;
  overflow-y: auto;
  b {
    display: inline-block;
    margin-bottom: 5px;
  }
  .agreement-text {
    color: #2f2f2f;
    width: 80%;
    max-width: 1024px;
    min-width: 480px;
    margin: 20px auto 10px;
  }
  .return-button {
    text-align: center;
    padding: 25px;
    a {
      font-size: 18px;
      color: #2f2f2f;
      text-decoration: underline;
    }
  }
}
</style>
